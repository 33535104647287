<template>
  <b-card>
    <b-card-text class="text-center">
      <h3 class="m-0">
        Página en Construcción
      </h3>
      <font-awesome-icon icon="fas fa-tools" />
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'PaginaEnConstruccion',
}
</script>

<style lang="scss" scoped>
</style>
